import React from "react";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";
import {
  AiFillCaretRight,
  AiOutlineArrowRight,
  AiOutlineArrowUp,
} from "react-icons/ai";
import { useState } from "react";
import SavePlusAccountComponent from "../../Components/SavePlusAccount/SavePlusAccountComponent";
import ContainsArea from "../../Components/ContainsArea/ContainsArea";

import { useEffect } from "react";


const IndoVehicleLoan = () => {
  const img1 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/personalaccounts-8.png";
const BackgroundBrundcrumb = "https://izbassetsmain.blob.core.windows.net/izbwebassets/indo-home-loan-banner.png";
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const accountContent =
    "With our Indo Vehicle Loans you dream car can be a reality. Bring your dreams to life with competitive interest rates and low processing fees, making it easy for you to access the financing you need to achieve your dream. ";
  const [show, setShow] = useState(false);
  return (
    <>
      <Breadcrumb Heading="Indo Vehicle Loan" img={BackgroundBrundcrumb} show={true} btnTxt="Apply Now" />
      <SavePlusAccountComponent
        heading="Indo Vehicle Loan"
        subHeading="Know About"
        btnTxt="Apply Now"
        content={accountContent}
        img={img1}
        logo={true}
      />
      <ContainsArea />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-xl-12 justify-content-center">
              <div className="faq-style1__content">
                <ul className="accordion-box mt-5">
                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                        Eligibility
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Individual and corporate customers
                        </p>
                      )}
                    </div>
                  </li>
                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                        Purpose
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          To ﬁnance purchase of Brand-New vehicles from an
                          Authorized Automobile Dealer, including Electric.
                        </p>
                      )}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          To ﬁnance purchase of second-hand vehicles not more
                          than 5 years old for personal and business use from
                          reputable dealers.
                        </p>
                      )}
                    </div>
                  </li>
                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                        Security
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                         <span className="">
                         <AiFillCaretRight className="position-absolute mt-2" style={{marginLeft:"48px"}} />

                         <p className="" style={{paddingLeft:"70px"}}>
                         Charge on vehicle purchased out of bank ﬁnance.
                         </p>
                       </span>

                      )}
                      
                    </div>
                  </li>
                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                        Customer Contribution
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Minimum margin of 30% upfront.
                        </p>
                      )}
                    </div>
                  </li>
                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                        Repayment Period{" "}
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          For new vehicles, maximum 72 months.
                        </p>
                      )}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          For second vehicles, maximum 60 months.
                        </p>
                      )}
                    </div>
                  </li>
                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active mb-3"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                      Terms and Conditions Apply
                      </h3>
                    </div>
                  
                  </li>
                </ul>
              </div>
            </div>
          
          </div>
        </div>
      </section>
      <AllAcountsSection />
    </>
  );
};

export default IndoVehicleLoan;
