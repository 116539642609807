import React,{useRef} from "react";
import { useEffect } from "react";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";


// import BackgroundBrundcrumb from "../../assets/images/IZB/BackgroundBrundcrumb.png";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineShareAlt,
} from "react-icons/ai";

import { Link } from "react-router-dom";
import { CiClock1 } from "react-icons/ci";
import { BiMessageDetail } from "react-icons/bi";
const Stories = () => {
const BackgroundBrundcrumb = "https://izbassetsmain.blob.core.windows.net/izbwebassets/BackgroundBrundcrumb.png";
const storie1 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/11.png";
const storie2 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/12.png";
const storie21 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/q3twge.jpg";
const storie5 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/14.png";
const storie11 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/IZB-TENDER-BRANCH-FITOUT-PRESS-AD-25X4-RVS-V2.png";
const arrowwreport = "https://izbassetsmain.blob.core.windows.net/izbwebassets/report.png";
const arroww1 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/2023-annual report.pdf";
const arrowwreport2 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/Blog_Image.png";
const arrowwreport3 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/Thumbnail_Image.png";
const arrowwreport4 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/payment-card.png";
const aarating = "https://izbassetsmain.blob.core.windows.net/izbwebassets/AA-Credit.png";
const solar = "https://izbassetsmain.blob.core.windows.net/izbwebassets/solar-hybrid.png";
const rfp ="https://izbassetsmain.blob.core.windows.net/izbwebassets/RFP_Thumbnail.png";
const addendum = "https://izbassetsmain.blob.core.windows.net/izbwebassets/addendum-Thumbnail_2.png";
const arroww = "https://izbassetsmain.blob.core.windows.net/izbwebassets/chevron_right.svg";
const job = "https://izbassetsmain.blob.core.windows.net/izbwebassets/Thumb_Image.png";

  const sliderRef = useRef(null);
  console.log(sliderRef.current);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Breadcrumb Heading="Our Stories" img={BackgroundBrundcrumb} />
      <section>
      <div className="container">
        <div className="row">
          <div className="container">
            <div className="heading py-3">
              <h2 className="text-center">Our Stories</h2>
              <p className="text-center">All News And Updates</p>
            </div>
          
          </div>





          <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
            <a href="https://izbassetsmain.blob.core.windows.net/izbwebassets/Jobs%20October%202024.pdf">
              <div className="img-holder">
                <div className="inner">
                  <img className="imgresize" src={job} alt="" />
            
                    <div className="overlay-icon"></div>
                
                </div>
              </div>
              </a>


         
          <div className="text-holder">
  <h3 className="blog-title mt-4">
    <a href="https://izbassetsmain.blob.core.windows.net/izbwebassets/Jobs%20October%202024.pdf" style={{fontSize: "20px"}}>
    CAREER OPPORTUNITIES FOR INDIVIDUALS AT INDO ZAMBIA BANK<br/>
    </a><br/><br/>
    <span style={{fontSize: "15px", color: "gray"}}>Oct 15, 2024</span>
  </h3>
  <p className="" style={{marginBottom:"0rem"}}>
  Indo Zambia Bank Limited, a Commercial Bank which has been operating in Zambia since 1984 and has a branch network of 40 branches across the country, the Bank has exciting career opportunities for individuals seeking to grow in the banking and financial services sector
  </p>
  <br/>
  <a href="https://izbassetsmain.blob.core.windows.net/izbwebassets/Jobs%20October%202024.pdf">
    <h5 style={{ fontWeight: "500", color: "#9E1B1E", textAlign: "right" }}>
      Read More <img src={arroww1} alt="" width="8%"/>
    </h5>
  </a>
  <hr style={{ color: "#9E1B1E", margin: "0rem 0" }}/>
</div>







        </div>
  </div>









  <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
            <a href="https://izbassetsmain.blob.core.windows.net/izbwebassets/ADDENDUM%201-%20RFP.pdf">
              <div className="img-holder">
                <div className="inner">
                  <img className="imgresize" src={addendum} alt="" />
            
                    <div className="overlay-icon"></div>
                
                </div>
              </div>
              </a>


         
          <div className="text-holder">
  <h3 className="blog-title mt-4">
    <a href="https://izbassetsmain.blob.core.windows.net/izbwebassets/ADDENDUM%201-%20RFP.pdf" style={{fontSize: "20px"}}>
    ADDENDUM 1 FOR SOLAR CONSULTANCY TENDER     <br/>
    </a><br/><br/><br/><br/>
    <span style={{fontSize: "15px", color: "gray"}}>Aug 31, 2024</span>
  </h3>
  <p className="" style={{marginBottom:"0rem"}}>
  Consultants are advised to take note of the responses to queries raised for the aforementioned tender for consultancy services to undertake load analysis, design, supervision and commissioning of solar system for IZB
  </p>
  <br/>
  <a href="https://izbassetsmain.blob.core.windows.net/izbwebassets/ADDENDUM%201-%20RFP.pdf">
    <h5 style={{ fontWeight: "500", color: "#9E1B1E", textAlign: "right" }}>
      Read More <img src={arroww1} alt="" width="8%"/>
    </h5>
  </a>
  <hr style={{ color: "#9E1B1E", margin: "0rem 0" }}/>
</div>







        </div>
  </div>



  <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
            <a href="https://izbassetsmain.blob.core.windows.net/izbwebassets/RFP.pdf">
              <div className="img-holder">
                <div className="inner">
                  <img className="imgresize" src={rfp} alt="" />
            
                    <div className="overlay-icon"></div>
                
                </div>
              </div>
              </a>


         
          <div className="text-holder">
  <h3 className="blog-title mt-4">
    <a href="https://izbassetsmain.blob.core.windows.net/izbwebassets/RFP.pdf" style={{fontSize: "20px"}}>
    CANCELLED: REQUEST FOR PROPOSAL FOR THE IMPLEMENTATION OF THE PAYMENT CARD INDUSTRY DATA SECURITY STANDARD
      <br/>
    </a><br/>
    <span style={{fontSize: "15px", color: "gray"}}>Aug 31, 2024</span>
  </h3>
  <p className="" style={{marginBottom:"0rem"}}>
  Cancelled: Indo Zambia Bank invites sealed proposals from eligible prospective Consultants for the implementation of the payment Card Industry Data Security Standard (PCI DSS)
  </p>
  <br/><br/>
  <a href="https://izbassetsmain.blob.core.windows.net/izbwebassets/RFP.pdf">
    <h5 style={{ fontWeight: "500", color: "#9E1B1E", textAlign: "right" }}>
      Read More <img src={arroww1} alt="" width="8%"/>
    </h5>
  </a>
  <hr style={{ color: "#9E1B1E", margin: "0rem 0" }}/>
</div>







        </div>
  </div>


  

   </div>
  </div>
</section>
    
    <section className="mt-4">
      <div className="container">
        <div className="row">
        <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
            <a href="https://izbassetsmain.blob.core.windows.net/izbwebassets/REQUEST FOR PROPOSAL FOR THE IMPLEMENTATION OF PAYMENT CARD INDUSTRIAL DATA SECURITY STANDARD AT IN.pdf">
              <div className="img-holder">
                <div className="inner">
                  <img className="imgresize" src={arrowwreport4} alt="" />
            
                    <div className="overlay-icon"></div>
                
                </div>
              </div>
              </a>


         
          <div className="text-holder">
  <h3 className="blog-title mt-4">
    <a href="https://izbassetsmain.blob.core.windows.net/izbwebassets/REQUEST FOR PROPOSAL FOR THE IMPLEMENTATION OF PAYMENT CARD INDUSTRIAL DATA SECURITY STANDARD AT IN.pdf" style={{fontSize: "20px"}}>
    Request For Proposal for The Implementation Of payment Card Industrial Data Security Standard
      <br/>
    </a><br/><br/><br/>
    <span style={{fontSize: "15px", color: "gray"}}>Aug 16, 2024</span>
  </h3>
  <p className="" style={{marginBottom:"0rem"}}>
  Indo Zambia Bank invites sealed proposals from eligible prospective Consultants for the implementation of the payment Card Industry Data Security Standard (PCI DSS)
  </p>
  <br/><br/>
  <a href="https://izbassetsmain.blob.core.windows.net/izbwebassets/REQUEST FOR PROPOSAL FOR THE IMPLEMENTATION OF PAYMENT CARD INDUSTRIAL DATA SECURITY STANDARD AT IN.pdf">
    <h5 style={{ fontWeight: "500", color: "#9E1B1E", textAlign: "right" }}>
      Read More <img src={arroww1} alt="" width="8%"/>
    </h5>
  </a>
  <hr style={{ color: "#9E1B1E", margin: "0rem 0" }}/>
</div>







        </div>
  </div>


        <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
            <a href="https://izbassetsmain.blob.core.windows.net/izbwebassets/REQUEST FOR PROPOSAL- CONSULTANCY FOR THE PROVISION OF DESIGN AND SUPERVISION OF A SOLAR HYBRID INV.pdf">
              <div className="img-holder">
                <div className="inner">
                  <img className="imgresize" src={arrowwreport3} alt="" />
            
                    <div className="overlay-icon"></div>
                
                </div>
              </div>
              </a>


         
          <div className="text-holder">
  <h3 className="blog-title mt-4">
    <a href="https://izbassetsmain.blob.core.windows.net/izbwebassets/REQUEST FOR PROPOSAL- CONSULTANCY FOR THE PROVISION OF DESIGN AND SUPERVISION OF A SOLAR HYBRID INV.pdf" style={{fontSize: "20px"}}>
    Request For Proposal- Consultancy For The Provision Of Design And Supervision Of A Solar Hybrid Inverter And Battery System Across Izb Branch Network
      <br/>
    </a><br/>
    <span style={{fontSize: "15px", color: "gray"}}>Aug 16, 2024</span>
  </h3>
  <p className="" style={{marginBottom:"0rem"}}>
  Indo Zambia Bank intends to install Solar Hybrid Inverters with Battery storage across its Branch and Automatic Teller Machines (ATMs) network Countrywide...
  </p>
  <br/>
  <a href="https://izbassetsmain.blob.core.windows.net/izbwebassets/REQUEST FOR PROPOSAL- CONSULTANCY FOR THE PROVISION OF DESIGN AND SUPERVISION OF A SOLAR HYBRID INV.pdf">
    <h5 style={{ fontWeight: "500", color: "#9E1B1E", textAlign: "right" }}>
      Read More <img src={arroww1} alt="" width="8%"/>
    </h5>
  </a>
  <hr style={{ color: "#9E1B1E", margin: "0rem 0" }}/>
</div>







        </div>
      </div>


           <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
            <a href="/TenderInvitations">
              <div className="img-holder">
                <div className="inner">
                  <img className="imgresize" src={arrowwreport2} alt="" />
            
                    <div className="overlay-icon"></div>
                
                </div>
              </div>
              </a>


         
          <div className="text-holder">
  <h3 className="blog-title mt-4">
    <a href="https://izbassetsmain.blob.core.windows.net/izbwebassets/vendor.pdf" style={{fontSize: "20px"}}>
    Tender Invitation For Recruitment & Management Of Agents, Services Of IZB Wallet Banking
      <br/>
    </a><br/><br/><br/><br/>
    <span style={{fontSize: "15px", color: "gray"}}>July 24, 2024</span>
  </h3>
  <p className="" style={{marginBottom:"0rem"}}>
  Indo Zambia Bank Limited has set aside funds to outsource the management of agents and targeted acquisition of customers...
  </p>
  <br/><br/>
  <a href="https://izbassetsmain.blob.core.windows.net/izbwebassets/vendor.pdf">
    <h5 style={{ fontWeight: "500", color: "#9E1B1E", textAlign: "right" }}>
      Read More <img src={arroww1} alt="" width="8%"/>
    </h5>
  </a>
  <hr style={{ color: "#9E1B1E", margin: "0rem 0" }}/>
</div>







        </div>
      </div>

       

    


       




        

          


          

       
        
          
          

       


        
        

        </div>
      </div>
    </section>
    <section className="mt-4">
      <div className="container">
        <div className="row">



          
        <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
            <a href="/Invitationn">
              <div className="img-holder">
                <div className="inner">
                  <img className="imgresize" src={solar} alt="" />
            
                    <div className="overlay-icon"></div>
                
                </div>
              </div>
              </a>


         
          <div className="text-holder">
  <h3 className="blog-title mt-4">
    <a href="https://izbassetsmain.blob.core.windows.net/izbwebassets/REVISED SOLICITATION DOCUMENT- TENDER FOR THE DESIGN INSTALLATION AND COMMISSION OF SOLAR SYSTEM SOLUT.pdf" style={{fontSize: "20px"}}>
    Tender For Solar Hybrid Inverter And Battery System Across IZB Branch Network
      <br/>
    </a><br/>
    <span style={{fontSize: "15px", color: "gray"}}>July 15, 2024</span>
  </h3><br/>
  <p className="" style={{marginBottom:"0rem"}}>
  Indo Zambia Bank has set aside funds for the procurement of a Solar System Solution across its Branch network...
  </p>
  
  <a href="https://izbassetsmain.blob.core.windows.net/izbwebassets/REVISED SOLICITATION DOCUMENT- TENDER FOR THE DESIGN INSTALLATION AND COMMISSION OF SOLAR SYSTEM SOLUT.pdf">
    <h5 style={{ fontWeight: "500", color: "#9E1B1E", textAlign: "right" }}>
      Read More <img src={arroww1} alt="" width="8%"/>
    </h5>
  </a>
  <hr style={{ color: "#9E1B1E", margin: "0rem 0" }}/>
</div>







        </div>
      </div>


        <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
            <a href="/AnnualReport">
              <div className="img-holder">
                <div className="inner">
                  <img className="imgresize" src={arrowwreport} alt="" />
            
                    <div className="overlay-icon"></div>
                
                </div>
              </div>
              </a>
              



<div className="text-holder">
  <h3 className="blog-title mt-4">
    <a href="https://izbassetsmain.blob.core.windows.net/izbwebassets/2023-annual report.pdf" style={{fontSize: "20px"}}>
      Indo Zambia Bank Annual Report - Dec 2023
      <br/><br/>
    </a><br/>
    <span style={{fontSize: "15px", color: "gray"}}>June 01, 2024</span>
  </h3>
  <p className="" style={{marginBottom:"0rem"}}>
  Dear Esteemed Stakeholders, it is with great pleasure that we present to you Indo Zambia Bank’s Annual Report...
  </p>
  <br/>
  <a href="https://izbassetsmain.blob.core.windows.net/izbwebassets/2023-annual report.pdf">
    <h5 style={{ fontWeight: "500", color: "#9E1B1E", textAlign: "right" }}>
      Read More <img src={arroww1} alt="" width="8%"/>
    </h5>
  </a>
  <hr style={{ color: "#9E1B1E", margin: "0rem 0" }}/>
</div>







            </div>
           </div>


        <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
            <a href="/AAcreditratings">
              <div className="img-holder">
                <div className="inner">
                  <img src={aarating} alt="" />
            
                    <div className="overlay-icon"></div>
                
                </div>
              </div>
              </a>
              <div className="text-holder">
                <h3 className="blog-title mt-4">
                  <a>Indo Zambia Bank Achieves AA Credit Rating!
<br/><br/></a>
                  <span style={{fontSize: "15px", color: "gray"}}>May 16, 2024</span>
                </h3>
                <p className="" style={{marginBottom:"0rem"}}>
                We are thrilled to announce that Indo Zambia Bank has been awarded the prestigious AA credit rating by Premier Credit Rating Agency...
                </p><br/><br/>
                <Link
                to="/AAcreditratings">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
            
              </div>
            </div>
          </div>
          

        <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
              <a href="/Monetarypoliceratenovember">
              <div className="img-holder">
                <div className="inner">
                  <img src={storie11} alt="" />
                
                    <div className="overlay-icon"></div>
                 
                </div>
              </div>
              </a>
              <div className="text-holder">
                <h3 className="blog-title mt-4">
 
                  <a>CUSTOMER NOTICE<br/>Monetary Policy Rate (MPR)<br/></a><br/>

                  <span style={{fontSize: "15px", color: "gray"}}>November 27, 2023</span>
                </h3>
                <p className="" style={{marginBottom:"0rem"}}>
                We wish to notify our esteemed Customers that the Bank of Zambia during
the 20th 21st November, 2023 MPC Quaterly Meeting, increased the
Monetary Policy ...
                </p><br/>
                <Link
                to="/Monetarypoliceratenovember">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
            
              </div>
            </div>
          </div>


        <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
                <a href="/Workerscopensation">
              <div className="img-holder">
                <div className="inner">
                  <img src={storie21} alt="" />
           
                    <div className="overlay-icon"></div>
              
                </div>
              </div>
              </a>
              <div className="text-holder">
                <h3 className="blog-title mt-4">
             
                  <a>IZB - Workers Compensation Fund Control Board Zambia E-Workers Smart Pay Launch
</a><br/>

                  <span style={{fontSize: "15px", color: "gray"}}>October 20, 2023</span>
                </h3>
                <p className="" style={{marginBottom:"0rem"}}>
                Indo Zambia Bank is proud to announce the launch of the provision of the E-Workers on our INDO Internet Banking platform .This INDO Net Banking platform ...
                </p><br/>
                <Link
                to="/Workerscopensation">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
            
              </div>
            </div>
          </div>

















       

        <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
              <a href="/Monetarypolicerate">
              <div className="img-holder">
                <div className="inner">
                  <img src={storie1} alt="" />
                
                    <div className="overlay-icon"></div>
                 
                </div>
              </div>
              </a>
              <div className="text-holder">
                <h3 className="blog-title mt-4">
                  {/* <a>MONETARY POLICY RATE</a><br/> */}
                  <a>Monetary Policy Rate</a><br/><br/><br/>

                  <span style={{fontSize: "15px", color: "gray"}}>Aug 28, 2023</span>
                </h3>
                <p className="" style={{marginBottom:"0rem"}}>
                We wish to notify our esteemed customers that the Bank of Zambia increased the Monetary Policy Rate (MPR) from 9.50% to 10%...
                </p><br/><br/>
                <Link
                to="/Monetarypolicerate">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
            <br/><br/>
              </div>
            </div>
          </div>


        <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
            <a href="/Indopremierbanking">
              <div className="img-holder">
                <div className="inner">
                  <img src={storie5} alt="" />
            
                    <div className="overlay-icon"></div>
                
                </div>
              </div>
              </a>
              <div className="text-holder">
                <h3 className="blog-title mt-4">
                  <a>Introducing Indo Premier Banking Suite - Tailored To Meet Your Banking Needs</a><br/>
                  <span style={{fontSize: "15px", color: "gray"}}>July 15, 2023</span>
                </h3>
                <p className="" style={{marginBottom:"0rem"}}>
                We cordially invite you to the Indo Premier Banking Suite. 
At Indo Zambia Bank (IZB), we understand and appreciate that you, our valued customers ...
                </p><br/>
                <Link
                to="/Indopremierbanking">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
            
              </div>
            </div>
          </div>

        <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
           <a href="/Officiallaunchofizbhq">
              <div className="img-holder">
                <div className="inner">
                  <img src={storie2} alt="" />
           
                    <div className="overlay-icon"></div>
              
                </div>
              </div>
              </a>
              <div className="text-holder">
                <h3 className="blog-title mt-4">
                  <a>The Official Launch Of The New Indo House Corporate Head Office</a><br/>
                 



                  <span style={{fontSize: "15px", color: "gray"}}>July 6, 2023</span>
                </h3>
                <p className="" style={{marginBottom:"0rem"}}>
                The Board, Management and Staff of the Indo Zambia Bank wish to extend their sincere gratitude to the President of the Republic of Zambia ...
                </p><br/>
                <Link
                to="/Officiallaunchofizbhq">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
            
              </div>
            </div>
          </div>

 
         







        









          
           </div>
      </div>
    </section>
      <AllAcountsSection />
    </>
  );
};

export default Stories;
